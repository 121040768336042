<script>
export default {
  name: "APrice",
  props: {
    priceValue: {
      type: Number,
      required: true,
    },
    noDisclaimer: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formatedPrice() {
      const coin = "R$";
      const integer = Math.floor(this.priceValue);
      const decimal = Math.floor((this.priceValue % 1).toFixed(2) * 100);
      const price =
        decimal != 0
          ? coin + integer + "," + decimal + "/mês"
          : coin + integer + "," + "00/mês";

      return price + (!this.noDisclaimer ? "*" : "");
    },
  },
};
</script>

<template lang="pug">
  div
    slot(:price="formatedPrice") {{formatedPrice}}
</template>

const Types = {
  NEXT_STEP: "NEXT_STEP",
  TOGGLE_MOREDETAILS: "TOGGLE_MOREDETAILS",
  SET_AUTOFETCH_STATUS: "SET_AUTOFETCH_STATUS",
  SET_GTM: "SET_GTM",
};

export const state = {
  gtm: process.env.VUE_APP_GTM,
  breadcrumb: {
    steps: [1, 2, 3, 4],
    currentStep: 1,
  },
  autoFetch: false,
  moreDetails: {
    isShowing: false,
  },
};

export const getters = {
  steps: state => state.breadcrumb.steps,
  currentStep: state => state.breadcrumb.currentStep,
  isShowingMoreDetails: state => state.moreDetails.isShowing,
  getAutoFetchStatus: state => state.autoFetch,
  getGtm: state => state.gtm,
};

export const mutations = {
  [Types.NEXT_STEP]: (state, newStep) =>
    (state.breadcrumb.currentStep = newStep),
  [Types.TOGGLE_MOREDETAILS]: (state, payload) =>
    (state.moreDetails.isShowing = payload),
  [Types.SET_AUTOFETCH_STATUS]: (state, status) => (state.autoFetch = status),
  [Types.SET_GTM]: (state, gtm) => (state.gtm = gtm),
};

export const actions = {
  setNewStep: ({ commit }, newStep) => commit(Types.NEXT_STEP, newStep),
  toggleMoreDetails: ({ commit, getters, dispatch }, sku) => {
    if (sku) dispatch("catalog/setSelectedPlan", sku, { root: true });
    commit(Types.TOGGLE_MOREDETAILS, !getters.isShowingMoreDetails);
  },
  setAutoFetchStatus: ({ commit }, status) =>
    commit(Types.SET_AUTOFETCH_STATUS, status),
  setGtm: ({ commit }, gtm) => commit(Types.SET_GTM, gtm),
};

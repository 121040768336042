import store from "@state/store";

export default function parseCardsInfos(plans) {
  return plans.map(plan => ({
    hasBadge: plan.info_plano.flag !== null,
    isCombo: plan.info_plano.flag
      ? plan.info_plano.flag.filter(item => item !== "bestoffer")[0]
      : null,
    isBestOffer:
      plan.info_plano.flag !== null
        ? plan.info_plano.flag.filter(item => item === "bestoffer").length > 0
        : false,
    dados: plan.info_plano.dados,
    dadosSubtitle: plan.info_plano.dados_detalhe.map(item => ({
      // url: this.plano.info_plano.url_img + item.img,
      url: item.img.split(".")[0],
      name: item.title,
    }))[0],
    old_price: plan.valores_plano.valor_oferta,
    price: plan.valores_plano.valor_com_desconto,
    price_description: plan.valores_plano.valor_descricao
      ? plan.valores_plano.valor_descricao
      : undefined,
    services: {
      titulo: plan.info_plano.apps.titulo,
      detalhe: plan.info_plano.apps.detalhe.map(item => ({
        url: store.getters["catalog/getUrlCDN"] + item.imagem,
        name: item.app,
      })),
    },
    detalhes: plan.info_plano.detalhe.filter(plan => !plan.ver_mais),
    viewMore: plan.info_plano.detalhe.filter(plan => plan.ver_mais),
    imgFeature: plan.info_plano.img_card
      ? store.getters["catalog/getUrlCDN"] + plan.info_plano.img_card
      : null,
    disclaimer: plan.info_plano.texto_sidebar,
    sku: plan.info_plano.sku,
    ordem: plan.info_plano.ordem,
    view: plan.info_plano.view,
    detalhe: plan.info_plano.detalhe,
  }));
}

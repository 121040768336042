import Vue from "vue";
import gtm from "@src/plugins/gtm";

import { router } from "@src/configs";

const configGtm = (enableInDev = true) => {
  if (process.env.NODE_ENV !== "development" || enableInDev) {
    Vue.use(gtm, {
      vueRouter: router,
      ignoredViews: ["checkout"],
      trackOnNextTick: true,
    });
  }
};

export default configGtm;

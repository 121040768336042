<script>
import OHeader from "@components/organism/o-header";
import CleanLayout from "@layouts/clean";
import ALoading from "../components/atoms/a-loading.vue";
import { mapGetters } from "vuex";
import createGtmScript from "@utils/create-gtm-script";
export default {
  name: "MinimalLayout",
  components: {
    OHeader,
    OMoreDetails: () => import("@components/organism/o-more-details.vue"),
    OFooter: () => import("@components/organism/o-footer"),
    CleanLayout,
    ALoading,
  },
  props: {
    hasSidebar: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    const gtm = this.gtm;
    const scriptId = "gtm-config";
    const currentScript = document.getElementById(scriptId);
    if (!currentScript) {
      createGtmScript(gtm, scriptId);
    }
  },
  computed: {
    ...mapGetters({
      isAPICallInProgress: "isAPICallInProgress",
      gtm: "ui/getGtm",
    }),
    noBreadcrumb() {
      return this.$route.meta.noBreadcrumb
        ? this.$route.meta.noBreadcrumb
        : false;
    },
    noFooter() {
      return this.$route.meta.noFooter ? this.$route.meta.noFooter : false;
    },
    isMinimal() {
      return this.$route.meta.isMinimal ? this.$route.meta.isMinimal : false;
    },
    noPadding() {
      return this.$route.meta.noPadding ? this.$route.meta.noPadding : false;
    },
    hasCarousel() {
      return this.$route.meta.hasCarousel
        ? this.$route.meta.hasCarousel
        : false;
    },
  },
};
</script>

<template>
  <section
    class="flex-grow md:flex md:flex-col md:max-h-full-fixed "
    :class="{
      'md:h-screen': !hasSidebar,
      'md:h-full pt-14 md:p-0': hasSidebar,
    }"
  >
    <o-header
      :no-breadcrumb="noBreadcrumb"
      class=""
      :class="{
        'md:-mt-4': !isMinimal,
        'md:max-w-5xl w-full md:mx-auto': 1,
      }"
    />
    <main
      class="flex-grow overflow-y-auto box-border"
      :class="{
        'px-4': !noPadding,
        'md:max-w-5xl w-full md:mx-auto': 1,
      }"
    >
      <clean-layout />
    </main>

    <o-footer
      v-if="!noFooter"
      class="py-4 box-border px-4 md:max-w-5xl w-full md:mx-auto"
      :class="{ 'md:pb-0': hasSidebar }"
    />
    <o-more-details v-if="hasCarousel" />
    <a-loading v-if="isAPICallInProgress" />
  </section>
</template>

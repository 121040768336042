<script>
import IconBase from "@components/icons/_icon-base.vue";
import IconLogo from "@components/icons/_icon-logo.vue";
import MBreadcrumb from "@components/molecules/m-breadcrumb.vue";

export default {
  name: "OHeader",
  components: {
    IconBase,
    IconLogo,
    MBreadcrumb,
  },
  props: {
    noBreadcrumb: Boolean,
  },
};
</script>

<template lang="pug">
  header( class="flex justify-between items-center px-4")
    IconLogo
    MBreadcrumb(v-if="!noBreadcrumb")  
</template>

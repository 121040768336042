<script>
import AText from "@components/atoms/a-text";
import ATooltip from "@components/atoms/a-tooltip.vue";
import AImage from "@components/atoms/a-image.vue";
import isArray from "~/src/utils/is-array";
import { mapGetters } from "vuex";

export default {
  name: "MFeatureItem",
  components: {
    AText,
    ATooltip,
    AImage,
  },
  props: {
    feature: Object,
    light: Boolean,
  },
  methods: {
    getPosition(path) {
      return path.idx < path.size / 3
        ? "bottom-right"
        : path.idx < (path.size / 3) * 2
        ? "bottom-center"
        : "bottom-left";
    },
  },
  computed: {
    ...mapGetters({
      getUrlCDN: "catalog/getUrlCDN",
    }),
    paths() {
      if (isArray(this.feature.detalhe))
        return this.feature.detalhe.map(item => ({
          name: item.app,
          url: this.getUrlCDN + item.imagem,
        }));
      return null;
    },
  },
};
</script>

<template lang="pug">
div( class="mb-3")
  a-text(
    tag="h3"
    class="text-sm font-medium"
    :class="{'text-light-0' : !light, 'text-dark-200' : light}"
    ignore-style) {{feature.titulo}}
  a-text(
    v-if="feature.detalhe !== null && (typeof feature.detalhe === 'string')"
    tag="p"
    class="text-xs font-medium mt-1"
    :class="{ 'text-primary-0' : !light, 'text-dark' : light}"
    v-html="feature.detalhe"
    ignore-style)
  AImage(v-else :paths="paths")
    template(slot-scope="{path}")
      ATooltip(:text="path.name" position="bottom-right" class="ml-2 first:ml-0 mt-1 " :position="getPosition(path)")
        AImage(:paths="[path]" no-title classList="w-6 transform -translate-x-2")
  //- ATooltip(v-else v-for="(item, idx) in feature.detalhe" :key="idx" :text="item.app" position="top-center" :position="(idx + 1) <= (feature.detalhe.length/3) ? 'bottom-right' : (idx + 1) <= (feature.detalhe.length/3) * 2 ? 'bottom-center' : 'bottom-left'" class="mt-2 ml-3")
  //-   img(:src="item.imagem")
</template>

<script>
export default {
  name: "AButton",
  props: {
    to: {
      type: [Object, String],
    },
    href: String,
    id: String,
    target: String,
    compressed: Boolean,
    disabled: Boolean,
    success: Boolean,
    secondary: Boolean,
    icon: Boolean,
  },
  computed: {
    computedTarget() {
      return this.target || (this.href ? "_blank" : null);
    },
    tag() {
      if (this.href) return "a";
      if (this.to) return "router-link";
      return "button";
    },
    neutral() {
      return !this.success && !this.secondary && !this.icon;
    },
  },
  methods: {
    handleClick(event) {
      /**
       * Click event
       * @type {Event}
       */
      this.$emit("click", event);
    },
  },
};
</script>

<template>
  <component
    :is="tag"
    :id="id"
    :to="to"
    :href="href"
    :disabled="disabled"
    :target="computedTarget"
    @click="handleClick"
    @mouseleave="$emit('mouseleave')"
    class="a-button rounded-full w-full sm:w-auto content-center transition-all cursor-pointer outline-none flex flex-no-wrap items-center justify-center focus:outline-none"
    :class="{
      'bg-success hover:bg-success-200 active:bg-success-300 text-light': success,
      'bg-secondary hover:bg-secondary-200 active:bg-secondary-300 text-light': secondary,
      'bg-light hover:bg-light-200 active:bg-light-300 text-dark': neutral,
      'bg-primary hover:bg-primary-200 active:bg-primary-300 text-light': icon,
      'bg-light-100 hover:bg-light-100 active:bg-light-100 text-dark-0 cursor-not-allowed': disabled,
      'py-4 px-8 text-base': !compressed,
      'py-2 px-8 text-sm min-w-full': compressed,
    }"
  >
    <slot />
  </component>
</template>

<style lang="scss">
.a-button {
  svg {
    @apply mr-1;
  }
}
</style>

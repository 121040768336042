<script>
import AText from "@components/atoms/a-text";
import APrice from "@components/atoms/a-price.vue";

import MFeatureItem from "@components/molecules/m-feature-item";
import MSharedIcons from "@components/molecules/m-shared-icons";

import IconBase from "@components/icons/_icon-base";
import IconCart from "@components/icons/_icon-cart";
import IconChevron from "@components/icons/_icon-chevron";
import IconCheck from "@components/icons/_icon-check";
import { mapGetters } from "vuex";

export default {
  name: "OSidebar",
  components: {
    AText,
    APrice,
    MFeatureItem,
    MSharedIcons,
    IconBase,
    IconCart,
    IconChevron,
    IconCheck,
  },
  data: () => ({
    isOpen: false,
    sidebarData: {
      features: [
        {
          titulo: "Fixo Ilimitado Brasilll",
          detalhe: null,
        },
        {
          titulo: "Wi-Fi grátis",
          detalhe: "Download até 50Mbps e Upload até 25Mbps",
        },
        {
          titulo: "Serviços digitais*",
          detalhe: [
            {
              app: "Discovery Kids!",
              imagem: "app-dkids.svg",
            },
            {
              app: "Watch ESPN",
              imagem: "app-watchespn.svg",
            },
            {
              app: "TIM Protege",
              imagem: "app-vivoprotege.svg",
            },
            {
              app: "CN Já!",
              imagem: "app-cnja.svg",
            },
            {
              app: "Esporte interativo",
              imagem: "app-ei.svg",
            },
            {
              app: "TIM Games 4U",
              imagem: "app-vivog4u.svg",
            },
          ],
        },
        {
          titulo: "TV Full HD",
          detalhe: "Com 103 canais, sendo 89 em HD",
        },
        {
          titulo: "Netflix",
          detalhe: "Assinatura padrão | 2 telas simultâneas em HD",
        },
      ],
    },
  }),
  methods: {
    toggle() {
      this.isOpen = this.isOpen ? false : true;
      if (window && window.scrollTo) window.scrollTo(0, 0);
    },
  },
  computed: {
    ...mapGetters({
      plano: "catalog/getSidebarInfos",
      infoParabens: "order/getInfoParabens",
      bonusText: "getBonusText",
    }),
    page() {
      const routeName = this.$route.name;
      switch (routeName) {
        case "DadosPessoais":
          return "dados";
        case "Endereco":
          return "complemento";
        case "Pagamento":
          return "pagamento";
        case "Parabens":
          return "sucesso";
        default:
          return "dados";
      }
    },
    isParabens() {
      return this.$route.name === "Parabens";
    },
    features() {
      const viewMoreFeatures = this.plano.viewMore
        .map(item => item.detalhe)
        .reduce((acc, current) => [...acc, ...current], []);

      return !this.isParabens
        ? [...this.plano.detalhes, ...viewMoreFeatures]
        : [...this.infoParabens];
    },
    detailTitle() {
      const detail = this.plano.detalhe.find(
        item => item.titulo === "Taxa Habilitação"
      );
      return detail ? detail.detalhe[0].titulo.replace("*", "") : "";
    },
  },
};
</script>

<template lang="pug">
.o-sidebar( 
  class="fixed top-0 z-20 flex flex-col justify-between w-screen overflow-hidden transition-all md:relative bg-primary shadow-glow text-light duration-600 ease-in-out-quint"
  :class="{'h-16 rounded-bl-1/4 rounded-br-1/4 pt-2-1/2': !isOpen, 'h-screen max-h-full-fixed rounded-none pt-5': isOpen, 'md:h-screen md:max-h-full md:max-w-xs md:rounded-lg md:pt-5': !isOpen || isOpen}"
  )
  //-  Icon Toggle
  .o-sidebar--top-wrapper( class="")
    .o-sidebar--icon-toggle( 
      :data-ic-item="`sec-${page}-carrinho`"
      class="absolute top-0 right-0 z-30 block p-4 transition-transform duration-300 ease-in-out delay-500 cursor-pointer"
      :class="{'transform rotate-0': !isOpen, 'transform rotate-180': isOpen, 'md:hidden' : !isOpen || isOpen}"
      @click="toggle")
      icon-base(:size='8')
        icon-chevron
    //- Title da sidebar
    .o-sidebar--title(
      class="flex items-center px-4 transition-all duration-300 ease-in-out md:px-6")
      icon-base
        icon-cart(v-if="!isParabens")
        icon-check(v-else)
      a-text(tag='p' class="pl-1 text-xs font-medium text-light" ignore-style)
        | {{ !isParabens ?  "Seu plano" : "Pedido Realizado"}}

    //- Plano + preço (apenas no mobile)
    .o-sidebar--plan-price(
      class="flex items-center px-4 transition-all duration-300 ease-in-out md:px-6 text-secondary-0"
      :class="{'mt-1': !isOpen, 'mt-4': isOpen, 'md:mt-4': !isOpen || isOpen}")
      a-text(
        tag='p'
        class="font-bold leading-none transition-all duration-300 ease-in-out"
        :class="{'text-2xl': !isOpen && !isParabens, 'text-4xl': isOpen && !isParabens, 'md:text-4xl': !isParabens, 'text-xl': !isOpen && isParabens, 'text-2xl': isOpen && isParabens, 'md:text-2xl': isParabens}"
        ignore-style) {{plano.dados}}
      a-price( :price-value="plano.price" v-if="!isParabens")
        template( slot-scope="{price}")
          a-text(
            tag="p"
            class="ml-5 text-sm font-medium leading-none transition-all duration-300 ease-in-out"
            :class="{'transform translate-x-0 opacity-1': !isOpen, 'transform -translate-x-1/2 opacity-0': isOpen, 'md:hidden': !isOpen || isOpen}"
            ignore-style)  {{ price }}

    .o-sidebar--inner-wrapper(
      class="h-full px-4 mt-2 overflow-y-auto transition-opacity md:px-6 duration-600 ease-in-out-quint"
      :class="{'opacity-0': !isOpen, 'opacity-100': isOpen, 'opacity-100': !isOpen || isOpen }"
      )
      template(v-if="isParabens")
        a-text(tag="p" class="text-base text-secondary-0" ignore-style) {{plano.dadosSubtitle.name}}
        a-price( :price-value="plano.price")
          template( slot-scope="{price}")
            a-text(
              tag="p"
              class="mt-2 mb-2 text-base font-medium leading-none text-light"
              ignore-style)  {{ price }}

      template(v-if="isParabens")
        a-text(tag="p" class="mb-8 text-base opacity-50 text-light" ignore-style) {{detailTitle}}

      m-feature-item(
        v-for="(feature, idx) in features"
        :key="idx"
        :feature="feature")

      a-text( v-if="!isParabens" tag="p" class="text-xs font-medium leading-none opacity-50 text-primary-0" ignore-style) {{plano.disclaimer}}
      div(v-if="bonusText" class="bg-secondary text-light p-3 rounded mt-6 relative font-bold text-sm")
        | {{ bonusText }}
        svg(width='28' height='6' viewbox='0 0 28 6' fill='none' xmlns='http://www.w3.org/2000/svg' class="text-secondary absolute -top-6-px left-1/2 transform -translate-x-1/2")
          path(d='M12.5858 0.49009L9.74904 2.86342C8.36542 4.021 7.67361 4.59979 6.86628 5.01371C6.1505 5.38068 5.37015 5.65111 4.55386 5.81507C3.63316 6 2.65479 6 0.698068 6H27.3019C25.3452 6 24.3668 6 23.4461 5.81507C22.6299 5.65111 21.8495 5.38068 21.1337 5.01371C20.3264 4.59979 19.6346 4.021 18.251 2.86342L15.4142 0.490089C14.6332 -0.163364 13.3668 -0.163363 12.5858 0.49009Z' fill='currentColor')

  .o-sidebar--footer(
    class="flex items-center justify-between px-4 transition-all duration-500 min-h-14 md:p-6 ease-in-out-quint"
    :class="{'transform translate-y-full': !isOpen, 'delay-700 transform translate-y-0': isOpen, 'md:translate-y-0': !isOpen || isOpen, 'bg-primary-200' : !isParabens}")
    
    m-shared-icons(v-if="isParabens")
    template(v-else)
      a-text(
        tag='p'
        class="text-sm font-bold leading-none transition-all duration-300 ease-in-out"
        ignore-style) Tudo isso por
      a-price( :price-value="plano.price")
        template( slot-scope="{price}")
          a-text(
            tag="p"
            class="text-xl font-medium leading-none text-secondary-0 "
            ignore-style)  {{ price }}


</template>

<style lang="scss" scoped>
.o-sidebar--top-wrapper {
  height: calc(100% - 3.5rem);
}
.o-sidebar--inner-wrapper {
  max-height: calc(100% - 4.8rem);
}
</style>
